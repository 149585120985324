import React, { createContext, useContext, useEffect, useState } from 'react'
import { DARK_THEME_CLASSNAME, SYSTEM_THEME_CLASSNAME, getStoredTheme, isDarkSystemTheme, setTheme } from '~/core/theming'

interface ThemeContextType {
  currentTheme: string
  setCurrentTheme: (theme: string) => void
  isDarkTheme: boolean
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

interface ThemeProviderProps {
  children: React.ReactNode
  initialTheme?: string
}

export function ThemeProvider({ children, initialTheme }: ThemeProviderProps) {
  const defaultTheme = initialTheme || getStoredTheme()
  const [currentTheme, setCurrentTheme] = useState(defaultTheme)
  const [isDarkTheme, setIsDarkTheme] = useState(defaultTheme === DARK_THEME_CLASSNAME)

  const updateIsDarkTheme = (theme: string) => {
    setIsDarkTheme(
      theme === DARK_THEME_CLASSNAME
      || (theme === SYSTEM_THEME_CLASSNAME && isDarkSystemTheme()),
    )
  }

  useEffect(() => {
    setTheme(currentTheme)
    updateIsDarkTheme(currentTheme)

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = () => updateIsDarkTheme(currentTheme)
    mediaQuery.addListener(handleChange)
    return () => mediaQuery.removeListener(handleChange)
  }, [currentTheme])

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme, isDarkTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export function useTheme() {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
